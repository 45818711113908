import React from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';

import {
  closeBasedSidebar,
  closeSidebar,
  openBasedSidebar,
  openSidebar,
} from 'src/actions/app';
import { EXPERT_CLASS_PATH_REGEXPS } from 'src/common/routes';
import { useHostCandidateApp } from 'src/modules/CandidateMobileApp/Common/hooks/useHostCandidateApp';
import {
  getCountry,
  getIsNotificationEnabled,
} from 'src/modules/ClientConfig/Selectors';

import { CountryCodes } from '../../../../common/enums';
import { ExpertPlatformCTA } from '../ExpertPlaformCTA/ExpertPlatformCTA';
import FakeChatButtonMenu from '../MenuDesktopComponents/FakeChatButtonMenu';
import { NavbarLogo } from '../NavbarLogo';
import {
  Container,
  ContainerRightContent,
  ContainerRightItem,
} from './MenuMobileComponents.sc';
import MobileRightMenu from './MobileRightMenu';

const NotificationButton = dynamic(() =>
  import(
    /* webpackChunkName: "NotificationButton" */ 'src/modules/Notification/Components/NotificationButton'
  )
);
/**
 * This is the mobile menu. It contains only the top navigation, namely the
 * logo, the 'Sign Up' link as well as the button that triggers the sidebar.
 */

let MobileMenu = props => {
  const { isNotificationEnabled, authenticated, className } = props;
  const shouldShowNotificationButton = isNotificationEnabled && authenticated;
  const router = useRouter();
  const { isHostCandidate } = useHostCandidateApp();
  const country = useSelector(getCountry);

  const showExpertRecruitmentLink = EXPERT_CLASS_PATH_REGEXPS.some(regexp =>
    regexp.test(router.pathname)
  );

  return (
    <Container className={className}>
      <NavbarLogo disabled={isHostCandidate} />
      <If condition={!isHostCandidate}>
        <ContainerRightContent>
          {showExpertRecruitmentLink && (
            <ContainerRightItem>
              <ExpertPlatformCTA />
            </ContainerRightItem>
          )}
          {shouldShowNotificationButton && (
            <ContainerRightItem>
              <NotificationButton />
            </ContainerRightItem>
          )}
          {country === CountryCodes.ID && <FakeChatButtonMenu />}

          <MobileRightMenu {...props} />
        </ContainerRightContent>
      </If>
    </Container>
  );
};

MobileMenu.propTypes = {
  className: PropTypes.string,
  authenticated: PropTypes.bool.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  openSidebar: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool,
  hideMenu: PropTypes.bool,
  isNotificationEnabled: PropTypes.bool.isRequired,
  closeBasedSidebar: PropTypes.func.isRequired,
  openBasedSidebar: PropTypes.func.isRequired,
  basedSidebarOpen: PropTypes.bool,
};

MobileMenu.defaultProps = {
  sidebarOpen: false,
};

MobileMenu = connect(
  state => ({
    sidebarOpen: state.app.sidebarOpen,
    basedSidebarOpen: state.app.basedSidebarOpen,
    hideMenu: state.app.hideMenu,
    isNotificationEnabled: getIsNotificationEnabled(state),
  }),
  dispatch => ({
    openSidebar: () => dispatch(openSidebar()),
    closeSidebar: () => dispatch(closeSidebar()),
    openBasedSidebar: () => dispatch(openBasedSidebar()),
    closeBasedSidebar: () => dispatch(closeBasedSidebar()),
  })
)(MobileMenu);

export default MobileMenu;
