import React from 'react';
import { Greyscale, MenuIcon } from 'glints-aries';
import { useDispatch, useSelector } from 'react-redux';

import {
  closeBasedSidebar,
  closeSidebar,
  openBasedSidebar,
  openSidebar,
} from 'src/actions/app';
import useConfig from 'src/common/hooks/useConfig';
import { getBasedSidebarOpen, getSidebarOpen } from 'src/selectors/app';

import { IconContainer, IconWrapper } from './ToggleButton.sc';

const ToggleButton = () => {
  const dispatch = useDispatch();
  const config = useConfig();
  const isBasedRootDomain =
    !config || Object.keys(config).length === 0 || config?.BASENAME === '/';

  const sidebarOpen = useSelector(getSidebarOpen);
  const basedSidebarOpen = useSelector(getBasedSidebarOpen);

  const onClickHandler = () => {
    if (isBasedRootDomain) {
      dispatch(basedSidebarOpen ? closeBasedSidebar() : openBasedSidebar());
      return;
    }

    dispatch(sidebarOpen ? closeSidebar() : openSidebar());
  };

  return (
    <IconContainer
      onClick={onClickHandler}
      role="navigation"
      aria-haspopup="true"
      aria-label="Open Sidebar Menu"
      tabIndex={0}
    >
      <IconWrapper tabIndex={-1}>
        <MenuIcon color={Greyscale.black} />
      </IconWrapper>
    </IconContainer>
  );
};

export default ToggleButton;
